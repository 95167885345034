import { useEffect, useState } from "react"
import { ArbitraryAlbumHorizontalFeed, RatingFeed } from "./rating_feed"
import { PatronBadge } from "../component/profile_badge"
import { Backend } from "../client/backend"
import { LinkTo } from "./list"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { useNavigate } from "react-router-dom"
import { FaExternalLinkAlt } from "react-icons/fa"
import { DiscoverPage } from "./discover"

const HeroBanner = () => {
  return (
    <>
      <div className="flex flex-row justify-around my-2">
        <h1 className="text-4xl font-bold"><span className="hover:animate-spin">💿</span> albms.net</h1>
      </div>
      <div className="flex flex-col my-4 leading-relaxed items-center lg:text-2xl text-lg">
        <p>Track music you've listened to.</p>
        <p>Find new music to listen to later.</p>
        <p>Share with friends what's good.</p>
      </div>
    </>
  )
}

const HighlightedReviews = () => {
  const navigate = useNavigate()

  return (
    <div className="mx-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col w-full">
          <div className='flex flex-row justify-between items-center w-full'>
            <div className='w-full'>
              <h2 className="text-xl font-bold my-2">
                From The Community
              </h2>
            </div>
            <div className="indicator">
              <span className='animate-pulse'>
                <span className="indicator-item indicator-start badge-sm badge badge-primary font-bold text-white">!</span>
              </span>
              <button onClick={() => navigate('/discover?page=1')} className="btn btn-success btn-sm">Explore</button>
            </div>
          </div>
          <p className="text-sm">Some highlighted reviews from other albms.net reviewers</p>
        </div>
      </div>
      <>
        <DiscoverPage />
      </>
    </div>
  )
}

const LoggedOutView = () => {
  const navigate = useNavigate()
  const session = useSessionContext() as any

  useEffect(() => {
    document.title = 'albms.net - home'
  }, [])

  return (
    <>
      <div className="m-4">
        <div className="card">
          <div id='backdropMask'
            style={{ 'backgroundImage': 'url("https://i.imgur.com/ijUVPaS.jpeg")' }}
            className="card-body text-white font-bold rounded-xl bg-cover filter bg-center">
            <HeroBanner />

            <div className="absolute inset-0 flex justify-end items-center">
              <div className="text-gray-300 !text-[6pt] uppercase rotate-90">
                <p>Father John Misty - The Telegraph</p>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="flex flex-col gap-2 items-center">
                <div className="card-actions justify-around">
                  <div className="indicator flex flex-col items-center gap-2">
                    {
                      session.doesSessionExist ? (
                        <button onClick={() => {
                          window.open('https://forms.gle/jU5BEFyj4KvG3UKP9', '_blank')
                        }} className="btn btn-success shadow-xl">FEEDBACK SURVEY <span className="text-gray-800 text-xs"><FaExternalLinkAlt /></span></button>
                      ) : (
                        <button onClick={() => navigate('/auth')} className="btn btn-success shadow-xl">GET STARTED &mdash; IT'S FREE!</button>
                      )
                    }

                    <button onClick={() => navigate('/pro')} className="btn btn-xs btn-primary shadow-xl">Support Us / Upgrade</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-4">
        <div className="flex flex-row justify-between">
          <h2 className="text-xl font-bold my-2">Popular This Week</h2>
        </div>

        <ArbitraryAlbumHorizontalFeed />
      </div>

      <HighlightedReviews />
    </>
  )
}

const Home = () => {
  const [tier, setTier] = useState<any>('BASIC')

  useEffect(() => {
    Backend.myStats()
      .then((obj) => {
        if (obj && obj.tier) {
          setTier(obj.tier)
        }
      })
  }, [])

  return (
    <>
      <LoggedOutView />
    </>
  )
}

export {
  Home
}
