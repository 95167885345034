import { useEffect, useRef, useState } from "react"
import { Backend } from "../client/backend"
import { FeedItemContainer } from "../component/feed_items"

const DefaultPredicate = (items: Array<any>) => {
  return items
    // .filter((i) => i.content.length > 0)
    .filter((i) => !i.is_archived)
    .slice(0,6)
}

const DiscoverPage = ({ predicate = DefaultPredicate }) => {
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);
  const loaderRef = useRef(null)
  const [hasMoreResults, setHasMoreResults] = useState(true)

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    Backend.getDiscoverFeed(pageIndex)
      .then((response) => response.json())
      .then((obj) => {
        if (obj.length === 0) {
          setHasMoreResults(false)
        } else {
          setResults((prev: any) => predicate([...prev, ...obj]))
          setPageIndex(prev => prev + 1)
        }
      })
      .finally(() => {
        setLoading(false)
        if (loaderRef.current && hasMoreResults) {
          // observer.current.observe(loaderRef.current)
        }
      })
  }

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <>
      <div className='grid grid-cols-1 lg:grid-cols-2 my-2'>
        <FeedItemContainer
          sort={undefined}
          asc={false}
          showMiniRating
          results={results}
          responsive />
      </div>
    </>
  )
}

export {
  DiscoverPage,
}