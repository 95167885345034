import { useEffect, useState } from "react"
import { FaExternalLinkAlt, FaSearch } from "react-icons/fa";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { Backend } from "../client/backend";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { useLocation, useNavigate } from "react-router-dom";

const UserNavLinks = () => {
  const session = useSessionContext() as any
  const [stats, setStats] = useState<any>(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    if (session) {
      // get user stats. return an image url and username
      Backend.myStats()
        .then((obj) => setStats(obj))
    }
  }, [session])

  const handleSignOut = () => {
    Promise.resolve(signOut())
      .then(() => {
        navigate('/')
      })
  }

  if (session.loading || session.doesSessionExist == false) {
    return (
      <></>
    )
  }

  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar m-1">
        <div className="w-10">
          <img decoding="async" loading='eager' className='rounded-full' src={(stats && stats.imageUrl) ? stats.imageUrl : `https://pub-c9000bc1dc1d4bd58533ca59a26f8978.r2.dev/pp/default-pp.png`} />
        </div>
      </div>

      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow rounded-box w-52">
        {(stats && stats.username) && (
          <li>
            <a href={`/@${stats.username}`}>
              <button>View Profile</button>
            </a>
          </li>
        )}

        <li><button onClick={handleSignOut} className="text-red-500">Sign Out</button></li>
      </ul>
    </div>
  )
}

// TODO(FELIX): 
// 1. hide if on search page
// 2. if on search page populate query
const SearchForwarder = () => {
  const [query, setQuery] = useState<any>(undefined)
  const [path, setPath] = useState<any>(undefined)
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className="flex flex-row gap-1 items-center w-full">
      {
        (location.pathname === '/') && (
          <div className="flex flex-row w-full items-center gap-2">
            <div className="flex flex-col w-full">
              <input
                type="text"
                placeholder={'Search for an album'}
                className="input w-full my-2"
                value={query}
                onChange={(e) => setQuery(e.target.value)} />
            </div>
            <div>
              <button onClick={() => navigate(`/search?q=${query}`)} className="btn btn-success text-xl btn-ghost btn-square">
                <FaSearch />
              </button>
            </div>
          </div>
        )
      }
    </div>
  )
}

const NavBar = () => {
  const session = useSessionContext() as any

  return (
    <div className="navbar flex flex-row justify-between">
      <div className="w-1/3">
        <a href='/' className="text-2xl font-bold btn btn-ghost flex flex-row items-center">
          <span>💿</span><span>albms.net</span>
        </a>
      </div>

      <div className="w-1/3 hidden md:flex">
        <SearchForwarder />
      </div>

      <div className="w-1/3 justify-end gap-2">
        {
          session.doesSessionExist && (
            <UserNavLinks />
          )
        }
      </div>
    </div>
  )
}

export {
  NavBar
}