import { useEffect, useRef, useState } from "react"
import { ImageWithErr } from "../component/image"
import { FaBars, FaTrash } from "react-icons/fa"
import React from "react"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useNavigate } from "react-router-dom"
import { Backend, List, ListItem as ListItemModel } from "../client/backend"
import { useAuth, useUser } from "@clerk/clerk-react"
import { RatingContainer } from "../component/rating"
import { Rating } from "react-simple-star-rating"
import { Search } from "../component/search"
import Session from "supertokens-auth-react/recipe/session";
import { RedirectIfNotSignedIn } from "../util/redirect"


const ListItem = ({ Name, Rank, Artist, AlbmsID, username, onDelete }: any) => {
  const [rating, setRating] = useState(0)

  useEffect(() => {
    if (username && AlbmsID) {
      Backend.getAlbumRating(AlbmsID, username)
        .then((obj) => {
          if (obj) {
            setRating(obj.rating)
          }
        })
    }
  }, [username, AlbmsID])

  return (
    <div className='flex flex-row justify-around gap-2 w-full mb-8'>
      <div className="w-12 flex flex-row justify-end items-center">
        <p className="text-lg font-bold px-1">{Rank + 1}.</p>
      </div>

      <div className="flex flex-row items-center w-1/4">
        <ImageWithErr
          alt={`covert art for ${Artist} - ${Name}`}
          src={Backend.imageFor(AlbmsID)} />
      </div>

      <div className='w-3/4 p-2'>
        <h2 className='font-bold'>{Name}</h2>
        <h3>{Artist}</h3>
      </div>

      <div className='w-1/4 flex flex-row px-1 justify-around items-center'>
        <div className='flex flex-row justify-around'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-around'>
              <Rating
                size={14}
                SVGclassName='inline-block'
                initialValue={rating / 2}
                allowFraction
                readonly />
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-row gap-4 w-1/4 text-xl justify-end self-center'>
        <span>
          <FaTrash onClick={() => onDelete({ Name, Artist })} />
        </span>
        <span onClick={() => { }}>
          <FaBars />
        </span>
      </div>
    </div>
  )
}

const ListPage = () => {
  return (
    <RedirectIfNotSignedIn>
      <ClassicListPage />
    </RedirectIfNotSignedIn>
  )
}

const LinkTo = ({ value, to, target = undefined }) => {
  return (
    <a className="text-blue-600 hover:cursor-pointer hover:underline" href={to} target={target}>{value}</a>
  )
}

const ClassicListPage = () => {
  const [myList, setMyList] = useState<any>([])
  const [title, setTitle] = useState<any>(undefined)
  const [description, setDescription] = useState<any>(undefined)
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const [profile, setProfile] = useState<any>(undefined)

  useEffect(() => {
    Backend.myStats()
      .then((obj) => setProfile(obj))
  }, [])

  const setSelectedAlbumMBID = ({ name, artist, id, albmsId }: any) => {
    if (myList.filter((item: any) => item.AlbmsID === albmsId).length !== 0) {
      alert('You already have this in the list mate')
      return;
    }

    setMyList((prev: any) => [...prev, {
      ...{
        Name: name,
        Artist: artist,
        AlbmsID: albmsId,
      },
      id: id,
    }])
  }

  const onDelete = (id: any) => {
    setMyList((prev: any) => [
      ...([...prev].filter((item: any) => item.id !== id))
    ])
  }

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      [...myList],
      result.source.index,
      result.destination.index
    );

    setMyList([...items]);
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: any) => ({});

  const handlePublish = () => {
    setSubmitting(true)

    const listBody: List = {
      title: title,
      description: description,
      items: [
        ...myList.map((item: any, index: any) => (
          {
            album_title: item.Name,
            artist_name: item.Artist,
            index: index,
            albms_id: item.AlbmsID,
          } as ListItemModel
        ))
      ]
    }

    Backend.submitList(listBody).then((obj: any) => {
      if (obj.external_id) {
        setSubmitting(false)
        navigate(`/listConfirmation?id=${obj.external_id}`)
      } else {
        throw new Error('Something went wrong!')
      }
    }).catch((e: any) => {
      setSubmitting(false)
    })
    return false
  }

  if (!profile) {
    return (
      <></>
    )
  }

  return (
    <div className="m-4">
      <div className='flex flex-col justify-around my-2 mb-4'>
        <h1 className="text-2xl font-bold">
          Create a new list
        </h1>
        <p>
          Create a ranked list to share with your friends or show on your profile.
        </p>
      </div>

      <div className='flex flex-col'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any, snapshot: any) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {myList.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided: any, snapshot: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ListItem Rank={index} username={profile?.username} onDelete={() => onDelete(item.id)} {...item} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="flex flex-row justify-end mt-0 my-6">
        {
          (myList || []).length !== 0 &&
          <button
            onClick={() => (document.getElementById('publish_list_modal') as any).showModal()}
            className="btn btn-success">Publish List</button>
        }
      </div>

      <Search tooltip='Search for an album to add to your list.'
        setSelectedAlbumMBID={setSelectedAlbumMBID} />

      <dialog id="publish_list_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Publish</h3>
          <p className="py-4">Give your list a title and an optional description.</p>

          <div>
            <input
              type="text"
              placeholder="My super great list"
              className="input input-bordered input-primary w-full"
              value={title}
              onChange={(e) => setTitle(e.target.value)} />
          </div>

          <div className="flex flex-col my-4">
            <div className="form-control">
              <textarea
                value={description}
                className="textarea textarea-bordered h-24"
                onChange={(e: any) => ((description || '').length < 5000) ? setDescription(e.target.value) : {}}
                placeholder="This was ..."></textarea>

              <label className="label">
                <span className="label-text-alt">{((description || '').length)}/5000 characters</span>
              </label>
            </div>
          </div>

          <div className="modal-action">
            <form method="dialog" className="flex flex-row justify-between gap-2 w-full">
              <button
                onClick={() => { }}
                className="btn">Cancel</button>
              <button
                onClick={(e: any) => {
                  if (!title || !handlePublish()) {
                    e.preventDefault()
                  }
                }}
                disabled={submitting}
                className="btn btn-success">Publish</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  )
}

export {
  ListPage,
  ListItem,
  LinkTo
}