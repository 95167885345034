
import { useEffect, useState } from "react"
import { Backend } from "../client/backend"
import 'react-image-crop/dist/ReactCrop.css'
import { FeedItem } from "../component/feed_items"
import { ResultsContainer } from "../component/favourites_container"
import { FaCalendarAlt, FaFire, FaLink, FaLocationArrow, FaPencilAlt, FaStar, FaThumbtack } from "react-icons/fa"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { ListItemThumbnail } from "./view_list_page"
import { sliceContent } from "../util/random"
import { LargeProfileBadge, PatronBadge, ProBadge } from "../component/profile_badge"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { LinkTo } from "./list"
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { Loading } from "../util/loading"
import { EmbeddedFeedTimeline } from "./feed_page"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ListLink = ({ external_id, title, description, is_pinned, albums = [] }) => {
  const navigate = useNavigate()
  const [listAlbums, setAlbums] = useState(albums || [])

  const onClick = () => {
    navigate(`/view/list/${external_id}`)
  }

  return (
    <div onClick={onClick} className="flex flex-row bg-neutral items-center p-1 rounded-lg w-full hover:cursor-pointer">
      <div className="flex flex-col w-2/4 p-2">
        <h3 className="font-bold">{title} {
          (is_pinned && <span data-tip={`Pinned`} className='tooltip tooltip-bottom tooltip-xs text-blue-500 mx-1'>
            <div className='text-lg rotate-45'><FaThumbtack /></div>
          </span>)
        }</h3>
        <p className="text-xs">{sliceContent(description || 'No description given', 48)}</p>
      </div>

      <div className="w-2/4 flex flex-row justify-end">
        <div className="flex flex-row w-full list-item-cards rounded-lg">
          {
            listAlbums.length == 0 && <span className="text-xs">This list is empty!</span>
          }
          {
            listAlbums
              .slice(0, Math.min(6, listAlbums.length))
              .map(({ albms_id }: any, index) =>
                <ListItemThumbnail key={`llt-${index}-${albms_id}`} index={index} albms_id={albms_id} />)
          }
        </div>
      </div>
    </div>
  )
}

const ListsForProfileContainer = ({ username }: any) => {
  const [lists, setLists] = useState<any>([])

  useEffect(() => {
    if (username) {
      Backend.getListsForUser(username)
        .then((obj: any) => {
          setLists(obj)
        })
    }
  }, [username])

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between items-center mx-4">
        <h2 className="text-xl font-bold">Lists</h2>
      </div>

      {
        (lists || []).length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      <div className="flex flex-col gap-3 my-2 mx-4">
        {
          (lists || [])
            .sort((a, b) => a.is_pinned ? -1 : 1)
            .map((list: any, index: number) => (
              <ListLink key={`ll-${index}-${list.external_id}`} {...list} />
            ))
        }
      </div>
    </div>
  )
}

const ReviewLogContainer = ({ results, username }: any) => {
  return (
    <div>
      <div className="flex flex-row gap-2 items-center mx-4">
        <h2 className="text-xl font-bold">Review Log</h2>
      </div>

      {
        results.length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      <div className="flex flex-row overflow-y-auto mx-4">
        <div className="flex flex-row justify-evenly my-2">
          {
            results &&
            results
              .slice(0, Math.min(results.length, 6))
              .map((result: any, index: any) =>
                <FeedItem
                  key={`recent-${index}`}
                  small
                  showMiniRating
                  showAlbumDetail
                  {...result} />)
          }        </div>
      </div>
    </div>
  )
}

const PinnedContainer = ({ results }: any) => {
  const [pinned, setPinned] = useState<any>([])

  useEffect(() => {
    setPinned([...results.filter((r: any) => r.is_pinned)])
  }, [results])

  return (
    <div>
      <h2 className="mx-4 text-xl font-bold">Pinned Albums</h2>
      {
        pinned.length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      <ResultsContainer>
        {
          pinned &&
          pinned
            .slice(0, Math.min(pinned.length, 6))
            .map((result: any, index: any) =>
              <FeedItem
                key={`pinned-${index}`}
                small
                showMiniRating
                showAlbumDetail
                clickable={`/view/album/${result.albms_id}`}
                {...result} />)
        }
      </ResultsContainer>
    </div>
  )
}

const linkToHumanForm = (t) => {
  try {
    const url = new URL(t);
    return url.hostname;
  } catch (error) {
    console.error("Invalid URL:", t);
    return null;
  }
}

const BannerPicModal = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false)

  const saveProfilePic = () => {
    setLoading(true)

    const formData = new FormData();
    formData.append('file', selectedImage);

    Backend.uploadBannerPic(formData)
      .then((resp) => {
        setLoading(false)
        if (resp.ok) {
          if (confirm('Uploaded ok! It might take a while for your changes to be reflected across the site')) {
            // hide modal
          }
        } else {
          alert('Upload failed. Try again later')
        }
      })
  }

  return (
    <dialog id="editBannerPicModal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>

        <h3 className="font-bold text-lg">Upload a banner picture</h3>
        <div className="mb-2">
          <p className="text-sm">
            Images must be PNG, JPEG, or JPG.
          </p>
        </div>

        <div>
          <input
            type="file"
            className="file-input w-full max-w-xs"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setSelectedImage(event.target.files[0]);
            }}
          />
        </div>

        <form method="dialog">
          <div className="my-4">
            <div className="flex flex-col items-center mb-2">
              {loading ? <><Loading /></> : <button
                className="btn btn-sm btn-wide btn-success"
                onClick={saveProfilePic}>
                Upload
              </button>}
            </div>
          </div>
        </form>
      </div>
    </dialog>
  )
}

const ProfilePicModal = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false)

  const saveProfilePic = () => {
    setLoading(true)

    const formData = new FormData();
    formData.append('file', selectedImage);

    Backend.uploadProfilePic(formData)
      .then((resp) => {
        setLoading(false)
        if (resp.ok) {
          if (confirm('Uploaded ok! It might take a while for your changes to be reflected across the site')) {
            // hide modal
          }
        } else {
          alert('Upload failed. Try again later')
        }
      })
  }

  return (
    <dialog id="editProfilePicModal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>

        <h3 className="font-bold text-lg">Upload a profile picture</h3>
        <div>
          <input
            type="file"
            className="file-input w-full max-w-xs"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setSelectedImage(event.target.files[0]);
            }}
          />
        </div>

        <form method="dialog">
          <div className="my-4">
            <div className="flex flex-col items-center mb-2">
              {loading ? <><Loading /></> : <button
                className="btn btn-sm btn-wide btn-success"
                onClick={saveProfilePic}>
                Upload
              </button>}
            </div>
          </div>
        </form>
      </div>
    </dialog>
  )
}

const WithProfileCheck = ({ userId, children }: any) => {
  const [exists, setExists] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (userId) {
      Backend.doesUserExist(userId)
        .then((exists) => {
          if (exists) {
            setExists(true)
          } else {
            navigate(`/404?ref=${window.location.href}`)
          }
        })
    }
  }, [userId])

  return (
    <>
      {exists && children}
    </>
  )
}

const ProfilePage = ({ userId }: any) => {
  const [profile, setProfile] = useState<any>(undefined)
  const [results, setResults] = useState<any>(undefined)
  const [ratings, setRatings] = useState<any>(undefined)
  const [isMyProfile, setIsMyProfile] = useState(false)
  const [stats, setStats] = useState<any>(0)

  const [newBioContent, setNewBioContent] = useState(undefined)
  const [newLinkContent, setLinkContent] = useState(undefined)
  const [newLocationContent, setLocationContent] = useState(undefined)

  const session = useSessionContext() as any

  useEffect(() => {
    Backend.myStats()
      .then((obj) => setProfile(obj))
  }, [userId])

  const editBioClicked = () => {
    // if we have a bio, load it into the edit bio modal
    if (stats && stats.bio) {
      setNewBioContent(stats.bio)
      setLinkContent(stats.link)
      setLocationContent(stats.location)
    }

    (document.getElementById('editMyBioModal') as any).showModal()
  }

  const customProfileClicked = () => {
    if (!isMyProfile) {
      return
    }

    (document.getElementById('editProfilePicModal') as any).showModal()
  }

  const customBannerClicked = () => {
    if (stats?.tier === 'BASIC') {
      alert('Please consider upgrading to a PRO or PATRON membership to upload a custom banner!')
      return
    }
    if (!isMyProfile) {
      return
    }

    (document.getElementById('editBannerPicModal') as any).showModal()
  }

  const onSave = () => {
    Backend.updateStats({
      bio: newBioContent,
      link: newLinkContent,
      location: newLocationContent,
    })
      .then((obj) => {
        setTimeout(() => {
          reloadStats()
        }, 100)
      })
  }

  useEffect(() => {
    if (userId) {
      document.title = `albms.net - ${userId}'s profile`

      reloadStats()

      Backend.getFeed(userId)
        .then((response) => response.json())
        .then((obj) => {
          setResults((obj || []).filter((r: any) => !r.is_archived))
        })

      Backend.getFeedRatings(userId)
        .then((response) => response.json())
        .then((obj) => {
          setRatings(obj)
        })
    }

    if (userId && profile) {
      if (userId === profile.username) {
        setIsMyProfile(true)
      }
    }
  }, [userId, profile])

  const reloadStats = () => {
    Backend.statsWithDetail(userId)
      .then((resp) => {
        return resp.json()
      })
      .then((obj) => {
        setStats(obj)
        setNewBioContent(obj.bio)
      })
  }

  return (
    <WithProfileCheck userId={userId}>
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-row justify-around w-full max-w-[1024px]">
          <div className="flex flex-row w-full">
            {/* Profile Picture and Edit Button */}
            <div className="flex flex-row justify-around items-center">
              <div className="flex flex-col w-full">
                <div
                  onClick={customProfileClicked}
                  className={`z-100 flex flex-row w-24 h-24 md:w-32 md:h-32 shadow-lg rounded-full mx-8 ${isMyProfile && 'hover:cursor-pointer'
                    }`}
                >
                  <img
                    loading="lazy"
                    className="z-200 rounded-full w-full bg-white object-cover"
                    src={stats?.imageUrl || ''}
                    alt="Profile"
                  />
                </div>

                {/* Edit Profile Button */}
                {session.doesSessionExist && isMyProfile && (
                  <div className="flex flex-row justify-around mt-2">
                    <button onClick={editBioClicked} className="btn btn-xs btn-ghost">
                      Edit Profile
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Profile Information */}
            <div className="flex flex-col w-4/5 mx-4 my-2">
              {/* Username Section */}
              <div className="flex flex-row items-center justify-between mb-2">
                <LargeProfileBadge noLink noImage username={userId} />
              </div>

              {/* Bio */}
              <div className="mb-2">
                <p>{stats.bio || 'Nothing to see here.'}</p>
              </div>

              {/* Additional Details */}
              <div className="flex flex-col text-xs gap-1">
                <div className="flex flex-row gap-2">
                  <span className="flex flex-row items-center gap-1">
                    <FaLocationArrow /> {stats.location || 'Earth'}
                  </span>
                  {stats.link && (
                    <span className="flex flex-row items-center gap-1">
                      <FaLink />
                      <LinkTo target="_blank" to={stats.link} value={linkToHumanForm(stats.link)} />
                    </span>
                  )}
                </div>
                <div className="flex flex-row">
                  <span className="flex flex-row items-center gap-1">
                    <FaCalendarAlt /> Joined{' '}
                    {stats.joined_at && moment(stats.joined_at).format('Do MMM, YYYY')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-8" />

        {
          ratings &&
          (results?.length || ratings.results?.length) > 0 && (
            <div className="grid w-full grid-cols-1 max-w-[1024px]">
              <PinnedContainer results={results || []} />
              <ReviewLogContainer username={userId} results={results || []} />
              <ListsForProfileContainer />
            </div>
          )
        }

        <div className='flex flex-col'>
          <div className="flex flex-row gap-2 items-center mx-4 mb-3">
            <h2 className="text-xl font-bold">Timeline</h2>
          </div>
          <EmbeddedFeedTimeline username={userId} />
        </div>

        <div className="mb-8" />

        <BannerPicModal />
        <ProfilePicModal />

        <dialog id="editMyBioModal" className="modal">
          <div className="modal-box">
            <form method="dialog">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>

            <h3 className="font-bold text-lg">Update your profile</h3>

            <form method="dialog">
              <div className="my-4">
                <p className="text-sm font-bold">Change your bio</p>
                <input
                  type="text"
                  placeholder="I like birds, bees, I like people."
                  className="input input-bordered input-primary w-full"
                  value={newBioContent}
                  onChange={(e) => setNewBioContent(e.target.value)}
                />
              </div>

              <div className="my-4">
                <p className="text-sm font-bold">Set your location</p>
                <input
                  type="text"
                  placeholder="Earth"
                  className="input input-bordered input-primary w-full"
                  value={newLocationContent}
                  onChange={(e) => setLocationContent(e.target.value)}
                />
              </div>

              <div className="my-4">
                <p className="text-sm font-bold">Link something</p>
                <input
                  type="url"
                  placeholder="https://google.com"
                  className="input input-bordered input-primary w-full"
                  value={newLinkContent}
                  onChange={(e) => setLinkContent(e.target.value)}
                />
              </div>

              <div>
                <p className="text-sm font-bold">Change your profile picture</p>
                <p className="text-xs">
                  To change your profile picture simply tap or click on your profile
                  picture in the view profile screen.
                </p>
              </div>

              <div className="my-4">
                <p className="text-sm font-bold">Upload a profile banner</p>
                {stats?.tier === 'BASIC' && <div className="alert alert-warning my-1">
                  <p className="text-left text-xs">
                    This feature is available to <ProBadge /> and <PatronBadge /> members. Please
                    consider becoming a member to support the site and have more features like banner
                    setting! <LinkTo value={'Upgrade today'} to='/pro' />.
                  </p>
                </div>}
                {stats?.tier !== 'BASIC' && <div className="my-1">
                  <button onClick={customBannerClicked} className="btn btn-sm btn-success">
                    Upload Custom Banner
                  </button>
                </div>}
              </div>

              <div className="flex flex-row justify-between">
                <button className="btn btn-sm btn-primary">
                  Cancel
                </button>

                <button onClick={onSave} className="btn btn-success btn-sm btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </dialog>
      </div>
    </WithProfileCheck >
  )
}

export {
  ProfilePage
}