import { useEffect } from "react"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import { MiscOverlays } from "../component/misc_overlay"
import { NavBar } from "../component/nav"
import { FaTwitter } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { Backend } from "../client/backend"
import { LinkTo } from "./list"

const Footer = () => {
  return (
    <footer className="footer footer-center mt-8 text-gray-600 p-10">
      <aside>
        <div className='flex flex-row gap-1 items-center'>
          <span>💿</span>
          <p className="font-bold">
            albms.net
          </p>
        </div>
        <p>Copyright © {new Date().getFullYear()} - All right reserved.</p>
        <div className='text-xs flex flex-row items-center gap-2'>
          <p className='text-xs'>Made with ❤️ by <LinkTo to='https://albms.net/@felix' value='@felix' />.</p>
          <p>
            <LinkTo to='https://albms.net/privacy.html' value='Privacy' />
          </p>
          &middot;
          <div>
            <a target='_blank' href='https://x.com/@AlbmsDotNet'><FaTwitter /></a>
          </div>
        </div>
      </aside>
    </footer>
  )
}

const RedirectIfNotOnboarded = ({ reactRouterDom }) => {
  if (!reactRouterDom) {
    return <></>
  }
  
  const navigate = useNavigate()
  const location = reactRouterDom.useLocation()

  // on ANY page we must redirect to onboarding flow
  // if we have not had our status set.
  useEffect(() => {
    if (location.pathname !== '/onboarding') {
      Backend.getOnboardingStatus()
        .then((obj) => {
          if (obj.ok) {
            obj.json().then((result) => {
              if (!result.onboarded) {
                navigate('/onboarding')
              }
            })
          }
        })
    }
  }, [location])

  return (
    <></>
  )
}

const Page = ({ reactRouterDom, children, title = undefined }: any) => {
  useEffect(() => {
    if (title) {
      document.title = `albms.net - ${title}`
    }
  }, [title, document])

  return (
    <SessionAuth requireAuth={false}>
      <RedirectIfNotOnboarded reactRouterDom={reactRouterDom} />

      <div className='flex flex-col min-h-screen'>
        <main className='flex-1'>
          <NavBar />

          <div className='flex flex-row justify-around'>
            <section className='w-screen md:w-2/3 xl:w-6/12'>
              {children}
            </section>
          </div>
        </main>

        <div className='flex-shrink-0'>
          <Footer />
        </div>

        <MiscOverlays />
      </div >
    </SessionAuth>
  )
}

export {
  Page
}