import { useEffect, useState } from "react"
import { Backend } from "../client/backend"
import { useNavigate } from "react-router-dom"

const Badge = ({ onClick = undefined, size = 'sm', children, classNames = undefined }) => {
  return (
    <span onClick={onClick} className={`${onClick && 'hover:cursor-pointer'} badge badge-${size} font-bold px-1 py-2 ${classNames}`}>{children}</span>
  )
}

const ProBadge = ({ size = 'xs' }) => {
  const navigate = useNavigate()
  const navigatePro = () => {
    navigate('/pro')
  }
  return (
    <Badge size={size} onClick={navigatePro} classNames={`bg-blue-500 text-white`}>PRO</Badge>
  )
}

const ModBadge = ({ size = 'xs' }) => {
  const navigate = useNavigate()
  const navigatePro = () => {
    navigate('/pro')
  }
  return (
    <Badge size={size} onClick={navigatePro} classNames={`bg-orange-600 text-white`}>MOD</Badge>
  )
}

const StaffBadge = ({ size = 'xs' }) => {
  const navigate = useNavigate()
  const navigatePro = () => {
    navigate('/pro')
  }
  return (
    <Badge size={size} onClick={navigatePro} classNames={`bg-orange-600 text-white`}>STAFF</Badge>
  )
}

const PatronBadge = ({ size = 'xs' }) => {
  const navigate = useNavigate()
  const navigatePro = () => {
    navigate('/pro')
  }
  return (
    <Badge size={size} onClick={navigatePro} classNames={`bg-gradient-to-r from-purple-500 to-blue-500 text-white`}>PATRON</Badge>
  )
}

const BigProfileBadge = ({ username, noImage = false, noLink = false }) => {
  const [stats, setStats] = useState<any>()

  useEffect(() => {
    if (username) {
      Backend.statsWithDetail(username)
        .then((resp) => resp.json())
        .then((obj) => {
          setStats(obj)
        })
    }
  }, [])

  if (stats) {
    return (
      <span className="inline-flex mx-1 my-0 p-0 justify-around flex-row items-center gap-1">
        {!noImage && <div><img className="w-8 h-8 shadow rounded-full" src={stats.imageUrl} /></div>}
        {
          noLink ? (
            <span className="font-bold text-lg">{username}</span>
          ) : (
            <a className='hover:cursor-pointer text-blue-500 font-bold hover:underline' href={`https://albms.net/@${username}`}>@{username}</a>
          )
        }

        {stats.tier == 'PRO' && <ProBadge />}
        {stats.tier == 'PATRON' && <PatronBadge />}
        {stats.tier == 'STAFF' && <StaffBadge />}
        {stats.tier == 'MOD' && <ModBadge />}
      </span>
    )
  }

  return (<></>)
}

const PopulatedProfileBadge = ({ username, imageUrl, tier, noImage = false, noLink = false }) => {
  return (
    <span className="inline-flex mx-1 my-0 p-0 justify-around flex-row items-center gap-1">
      {!noImage && <div><img className="w-4 h-4 rounded-full" src={imageUrl} /></div>}
      {
        noLink ? (
          <span className="font-bold text-lg">{username}</span>
        ) : (
          <a className='hover:cursor-pointer text-blue-500 font-bold hover:underline' href={`https://albms.net/@${username}`}>@{username}</a>
        )
      }

      {tier == 'PRO' && <ProBadge />}
      {tier == 'PATRON' && <PatronBadge />}
      {tier == 'STAFF' && <StaffBadge />}
      {tier == 'MOD' && <ModBadge />}
    </span>
  )
}

// FIXME use PopulatedProfileBadge
const ProfileBadge = ({ username, noImage = false, noLink = false }) => {
  const [stats, setStats] = useState<any>()

  useEffect(() => {
    if (username) {
      Backend.statsWithDetail(username)
        .then((resp) => resp.json())
        .then((obj) => {
          setStats(obj)
        })
    }
  }, [])

  if (stats) {
    return (
      <span className="inline-flex mx-1 my-0 p-0 justify-around flex-row items-center gap-1">
        {!noImage && <div><img className="w-4 h-4 rounded-full" src={stats.imageUrl} /></div>}
        {
          noLink ? (
            <span className="font-bold text-lg">{username}</span>
          ) : (
            <a className='hover:cursor-pointer text-blue-500 font-bold hover:underline' href={`https://albms.net/@${username}`}>@{username}</a>
          )
        }

        {stats.tier == 'PRO' && <ProBadge />}
        {stats.tier == 'PATRON' && <PatronBadge />}
        {stats.tier == 'STAFF' && <StaffBadge />}
        {stats.tier == 'MOD' && <ModBadge />}
      </span>
    )
  }

  return (<></>)
}

const LargeProfileBadge = ({ username, noImage = false, noLink = false }) => {
  const [stats, setStats] = useState<any>()

  useEffect(() => {
    if (username) {
      Backend.statsWithDetail(username)
        .then((resp) => resp.json())
        .then((obj) => {
          setStats(obj)
        })
    }
  }, [])

  if (stats) {
    return (
      <span className="inline-flex mx-1 my-0 p-0 justify-around flex-row items-center gap-1">
        {!noImage && <div><img className="w-4 h-4 rounded-full" src={stats.imageUrl} /></div>}
        {
          noLink ? (
            <span className="font-bold text-2xl">{username}</span>
          ) : (
            <a className='hover:cursor-pointer text-blue-500 font-bold hover:underline' href={`https://albms.net/@${username}`}>@{username}</a>
          )
        }

        {stats.tier == 'PRO' && <ProBadge />}
        {stats.tier == 'PATRON' && <PatronBadge />}
        {stats.tier == 'STAFF' && <StaffBadge />}
        {stats.tier == 'MOD' && <ModBadge />}
      </span>
    )
  }

  return (<></>)
}

export {
  ProfileBadge,
  LargeProfileBadge,
  BigProfileBadge,
  Badge,
  ProBadge,
  PatronBadge,
  PopulatedProfileBadge
}