import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Backend } from "../client/backend"
import { FeedItemContainer } from "../component/feed_items"
import { Page } from "./page"

const DefaultPredicate = (items: Array<any>) => {
  return items
    .filter((i) => !i.is_archived)
}

const DiscoverPagePage = () => {
  return (
    <Page>
      <DiscoverPageFeed />
    </Page>
  )
}

const DiscoverPageFeed = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const pageIndex = parseInt(queryParams.get('page')) || 1;

  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);
  const loaderRef = useRef(null)
  const [hasMoreResults, setHasMoreResults] = useState(true)

  useEffect(() => {
    document.title = 'albms.net - discover'
    loadMore();
  }, [])

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    Backend.getPublicFeed(pageIndex)
      .then((response) => response.json())
      .then((obj) => {
        if (obj.length === 0) {
          setHasMoreResults(false)
        } else {
          setResults((prev: any) => DefaultPredicate([...prev, ...obj]))
        }
      })
      .finally(() => {
        setLoading(false)
        if (loaderRef.current && hasMoreResults) {
          // observer.current.observe(loaderRef.current)
        }
      })
  }

  return (
    <div>
      <h2 className="text-4xl font-bold">Discovery Feed</h2>

      <section>
        <div className='flex flex-col my-2'>
          <FeedItemContainer
            sort={undefined}
            summaryOnly={false}
            asc={false}
            showMiniRating
            results={results}
            responsive />
        </div>
      </section>

      <div className='flex flex-row gap-1'>
        {(pageIndex > 0) && (
          <a className="btn btn-xs btn-primary" href={`discover/?page=${pageIndex - 1}`}>Prev</a>
        )}
        <a className="btn btn-xs btn-primary" href={`discover/?page=${pageIndex + 1}`}>Next</a>
      </div>
    </div>
  )
}

export {
  DiscoverPageFeed,
  DiscoverPagePage
}