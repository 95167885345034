import { useEffect, useState } from "react"
import { FaPlus } from "react-icons/fa"
import { Backend } from "../client/backend"
import { RatingFeedItemContainer } from "./rating_feed_items"
import { useLocation } from "react-router-dom"
import { SubmitDecision } from "../pages/submit_decision"
import { useSessionContext } from "supertokens-auth-react/recipe/session";



const Album = ({ title = false }) => {
  return (
    <span className={`${title && 'capitalize'}`}>alb<span className=''>u</span>m</span>
  )
}



const RecentlyInteractedAlbumsCarousel = ({ username }) => {
  const [data, setData] = useState<any>([])

  useEffect(() => {
    Backend.aotwSample(username).then((obj) => {
      setData(obj.results)
    })
  }, [])

  if (data) {
    return (
      <div>
        <p className="text-xs">
          The <Album /> that is selected will be based on this selection of music you have recently been
          interacting with
        </p>

        <div className="flex flex-row justify-around my-4">
          <div className='flex flex-row w-full overflow-y-auto'>
            <RatingFeedItemContainer
              small
              showMiniRating
              results={data} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

const MiscOverlays = () => {
  const session = useSessionContext() as any
  const location = useLocation()

  const goClicked = () => {
    (document.getElementById('goModal') as any).showModal()
  }

  return (
    <>
      {
        session.doesSessionExist && (location.pathname == '/' || location.pathname.startsWith('/@')) && (
          <div className="bottom-0 right-0 fixed md:right-1/4 md:-mr-8 mr-4 mb-4">
            <button onClick={goClicked} className="btn btn-circle btn-lg bg-blue-500 text-white text-2xl shadow-lg">
              <span><FaPlus /></span>
            </button>
          </div>
        )
      }

      <dialog id="goModal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>

          <h3 className="font-bold text-lg">What do you want to do?</h3>

          <form method="dialog">
            <SubmitDecision />
          </form>
        </div>
      </dialog>
    </>
  )
}

export {
  MiscOverlays,
  Album,
  RecentlyInteractedAlbumsCarousel
}