import { Rating } from "react-simple-star-rating"

const GenericRatingContainer = ({ rating, large, size }: any) => {
  const sizeStyles = (style) => {
    return {
      'small': 'w-8 h-8 border-2 bg-white',
      'large': 'w-24 h-24 border-4'
    }[style] || 'w-16 h-16 border-4'
  }

  const textSizeStyles = (style) => {
    return {
      'small': 'text-xs',
      'large': 'text-3xl',
    }[style] || 'text-2xl'
  }

  return (
    <div className={`flex my-1 items-center justify-center ${sizeStyles(large ? 'large' : size)} rounded-full`}>
      <div className={`${textSizeStyles(large ? 'large' : size)} font-bold`}>
        {[10, 0].includes(rating) ? rating : rating.toFixed(0)}
      </div>
    </div>
  );
}

const ResponsiveRatingContainer = ({ rating }) => {
  return (
    <div className='flex flex-row justify-around'>
      <div className='flex flex-col'>
        <div className='flex flex-row justify-around'>
          <Rating
            size={14}
            SVGclassName='inline-block'
            initialValue={rating / 2}
            allowFraction
            readonly />
        </div>
      </div>
    </div>
  )
}

const RatingContainer = ({ rating, large, size, generic = false }: any) => {
  const sizeStyles = (style) => {
    return {
      'small': 'w-8 h-8 border-2 bg-white',
      'large': 'w-24 h-24 border-4'
    }[style] || 'w-16 h-16 border-4'
  }

  const textSizeStyles = (style) => {
    return {
      'small': 'text-xs',
      'large': 'text-3xl',
    }[style] || 'text-2xl'
  }

  return (
    <div className={`flex my-1 items-center justify-center ${sizeStyles(large ? 'large' : size)} ${rating >= 8 ? 'border-green-500' : ''} ${rating == 10 ? 'bg-green-100 text-black' : 'bg-white text-gray-700'} rounded-full`}>
      <div className={`${textSizeStyles(large ? 'large' : size)} font-bold`}>
        {[10, 0].includes(rating) ? rating : parseFloat(rating || 0.).toFixed(1)}
      </div>
    </div>
  );
}

export {
  RatingContainer,
  GenericRatingContainer,
  ResponsiveRatingContainer
}