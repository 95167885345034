import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  useNavigate
} from 'react-router-dom'
import * as reactRouterDom from "react-router-dom";

import './index.css';
import { ReviewPage } from './pages/review';
import { Home } from './pages/home';
import { ClerkProvider, useAuth, useUser } from '@clerk/clerk-react';
import { Submit } from './pages/submit_page';
import { NavBar } from './component/nav';
import { RoutingPage } from './pages/routing_page';
import { ReviewConfirmation } from './pages/review_confirm';
import { LinkTo, ListPage } from './pages/list';
import { ListConfirmation } from './pages/list_confirm';
import { RatingConfirmation } from './pages/rating_confirm';
import { FeedPage } from './pages/feed_page';
import { RatingPage } from './pages/rating_page';
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from './pages/error_page';
import { ViewListPage } from './pages/view_list_page';
import { OnboardingPage } from './pages/onboarding_page';
import { Backend } from './client/backend';
import { ProPage } from './pages/pro_page';
import { ViewAlbumPage } from './pages/view_album';
import { ActivatePage } from './pages/activate';
import { StatsPage } from './pages/stats';
import { Explore } from './pages/explore';
// @ts-ignore
import * as Sentry from "@sentry/react";
import { CookiesPage, PrivacyPolicyPage } from './pages/static';
import { FaExternalLinkAlt, FaHome, FaPlus, FaRecordVinyl, FaSearch, FaTwitter } from 'react-icons/fa';
import { MiscOverlays } from './component/misc_overlay';
import { SearchPage } from './pages/search';

// SUPER TOKENS START

import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui'
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Session, { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";
import { ThirdPartyPasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartypasswordless/prebuiltui";
import ThirdParty from "supertokens-auth-react/recipe/thirdparty";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { ExplorePage } from './pages/explore_page';
import { ShufflePage } from './pages/shuffle_page';
import { DiscoverPageFeed, DiscoverPagePage } from './pages/discover_page';
import { Page as BasicPage } from './pages/page';

ThirdParty.init({
  getRedirectionURL: async (context) => {
    if (context.action === "SUCCESS") {
      let redirectToPath = context.redirectToPath;
      if (redirectToPath !== undefined) {
        return redirectToPath;
      }

      if (context.isNewPrimaryUser) {
        return "/onboarding"
      }
    }
    return undefined;
  }
})

SuperTokens.init({
  appInfo: {
    appName: "albms.net",
    apiDomain: "https://api.albms.net",
    websiteDomain: "https://albms.net",
    apiBasePath: "/auth",
    websiteBasePath: "/auth"
  },
  recipeList: [
    ThirdPartyPasswordless.init({
      contactMethod: "EMAIL",
      signInUpFeature: {
        privacyPolicyLink: "https://albms.net/privacy.html"
      }
    }),
    Session.init()
  ]
});

// CLERK START

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY && !process.env.REACT_APP_CLERK_PUBLISHABLE_KEY_STG) {
  throw new Error("Missing Publishable Key")
}

const clerkPubKey = process.env.NODE_ENV !== 'production'
  ? process.env.REACT_APP_CLERK_PUBLISHABLE_KEY_STG
  : process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error('No clerk pub key')
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./service-worker.js')
}

Sentry.init({
  dsn: "https://e4bb3842367b3bb9b5234d3567ba3d02@o4505794864676864.ingest.sentry.io/4506525731520512",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Page = ({ children, title = undefined }) => {
  return (
    <BasicPage reactRouterDom={reactRouterDom} title={title}>
      {children}
    </BasicPage>
  )
}

const appRoutes = [
  {
    path: "/",
    element: <Page><Home /></Page>
  },
  {
    path: "/roadmap",
    element: <Page title='roadmap'>
      <div className='mx-4'>
        <h2 className='text-2xl font-bold'>Roadmap on Trello</h2>
        <div className='leading-relaxed'>
          <p>
            Want to see what's going on behind the scenes? Check out our roadmap where you can
            see all of the open bugs and backlog items for work-streams.
          </p>
          <p>
            This is a public facing roadmap and is not a 1:1 reflection of the internal
            backlogs we have for albms.net
          </p>
        </div>
        <div className='flex flex-col items-center my-4'>
          <button onClick={() => window.open('https://trello.com/b/N06S93ZL/albmsnet-public-roadmap-and-bugs', '_blank')} className='btn btn-success'>
            View Roadmap <FaExternalLinkAlt />
          </button>
        </div>
      </div>
    </Page>
  },
  {
    path: "/discover/:page?",
    element: <DiscoverPagePage />
  },
  {
    path: "/search",
    element: <Page><SearchPage /></Page>
  },
  {
    path: "/shuffle",
    element: <Page><ShufflePage /></Page>
  },
  {
    path: "/list",
    element: <Page><ListPage /></Page>
  },
  {
    path: "/view/list/:id",
    element: <Page><ViewListPage /></Page>
  },
  {
    path: "/privacy",
    element: <Page><PrivacyPolicyPage /></Page>
  },
  {
    path: "/cookies",
    element: <Page><CookiesPage /></Page>
  },
  {
    path: "/onboarding",
    element: <Page><OnboardingPage /></Page>
  },
  {
    path: "/explore",
    element: <Page><ExplorePage /></Page>
  },
  {
    path: "/pro",
    element: <Page><ProPage /></Page>
  },
  {
    path: "/activate",
    element: <Page><ActivatePage /></Page>
  },
  {
    path: "/dash",
    element: <Page><StatsPage /></Page>
  },
  {
    path: "/view/album/:id",
    element: <Page><ViewAlbumPage /></Page>
  },
  {
    path: "/submit",
    element: <Page><Submit /></Page>
  },
  {
    path: "/review/:id",
    element: <Page><ReviewPage /></Page>
  },
  {

    path: "/reviewConfirmation",
    element: <Page><ReviewConfirmation /></Page>
  },
  {

    path: "/ratingConfirmation",
    element: <Page><RatingConfirmation /></Page>
  },
  {
    path: '/feed/:username',
    element: <Page><FeedPage /></Page>
  },

  // rating pages.
  {
    path: '/ratings/:username/page/:page',
    element: <Page><RatingPage /></Page>
  },
  {
    path: '/ratings/:username',
    element: <Page><RatingPage /></Page>
  },

  {
    path: "/listConfirmation",
    element: <Page><ListConfirmation /></Page>
  },
  {
    path: "/:stuff",
    element: <Page><RoutingPage /></Page>
  }
]

ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(
  <ClerkProvider publishableKey={clerkPubKey}>
    <ErrorBoundary fallback={<ErrorPage />}>
      <BrowserRouter>
        <Routes>
          {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyPasswordlessPreBuiltUI])}

          {
            appRoutes.map(({ path, element }) => <Route path={path} element={element} />)
          }
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  </ClerkProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();