import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'

export interface ListItem {
  index: number;
  album_title: string;
  artist_name: string;
  albms_id: string;
}

export interface List {
  title: string;
  description: string;
  items: Array<ListItem>;
}

export interface AlbumRating {
  rating: number;
  album_title: string;
  artist_name: string;
}

export interface UpdateAlbumReview {
  id: string;
  is_favourite?: boolean;
  is_archived?: boolean;
  is_pinned?: boolean;
  content?: string;
  rating?: number;
}

class Backend {
  static async fetch(url, options: any = {}) {
    const fp = localStorage.getItem('_fp')
    if (!fp) {
      Promise.resolve(getCurrentBrowserFingerPrint())
        .then((fp) => {
          if (fp && fp.length !== 0) {
            localStorage.setItem('_fp', fp)
          }
        })
    }
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'X-Anonymous-ID': fp,
      }
    })
  }

  static async fetchWithRetry(url, options: any = {}, retries = 3, backoff = 500) {
    try {
      const response = await Backend.fetch(url, options);
      if (!response.ok) throw new Error('Fetch failed.');
      return response;
    } catch (error) {
      if (error instanceof TypeError && error.message.includes('CORS')) {
        // Handling CORS error specifically - do not retry
        throw error;
      }
      if (retries === 0) throw error;
      await new Promise(resolve => setTimeout(resolve, backoff));
      return Backend.fetchWithRetry(url, options, retries - 1, backoff * 2);
    }
  }

  static getOnboardingStatus() {
    return Backend.fetch(`${Backend.getBackend()}/profile/onboardingStatus`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static async uploadBannerPic(file: any) {
    return Backend.fetch(`${Backend.getBackend()}/profile/banner`, {
      method: 'PUT',
      body: file,
      credentials: 'include'
    })
  }

  static async uploadProfilePic(file: any) {
    return Backend.fetch(`${Backend.getBackend()}/profile/pic`, {
      method: 'PUT',
      body: file,
      credentials: 'include'
    })
  }

  static verifyProfile(username: string) {
    return Backend.fetch(`${Backend.getBackend()}/profile/verify`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
      }),
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static activate(key: string) {
    return Backend.fetch(`${Backend.getBackend()}/subscriptions/activate?key=${key}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static getLists() {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/list/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static getListById(id: any) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/list?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static getAlbumRating(id: any, username: any) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/album/rating/${id}/for/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static imageFor(id: string, size = 'md') {
    return `https://img.albms.net/${id}/${size}.png`
  }

  static updateReview(payload: UpdateAlbumReview) {
    return Backend.fetch(`${Backend.getBackend()}/album/review`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    })
  }

  static getInfo(id: string) {
    const apiUrl = `${Backend.getBackend()}/album/v2/info?id=${id}`;

    return Backend.fetchWithRetry(apiUrl)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static search(query: string) {
    const apiUrl = `${Backend.getBackend()}/v2/album/search?query=${query}`;

    return Backend.fetchWithRetry(apiUrl)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static aotwSample(username: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/aotw/sample/${username}`)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static aotwShuffle(username: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/aotw/shuffle/${username}`)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static shuffle() {
    const apiUrl = `${Backend.getBackend()}/v3/album/shuffle`;

    return Backend.fetchWithRetry(apiUrl)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static updateStats(payload: any) {
    return Backend.fetch(`${Backend.getBackend()}/profile/stats`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    })
  }

  static myStats() {
    return Backend.fetch(`${Backend.getBackend()}/profile/detail`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static doesUserExist(user?: string) {
    return Backend.fetch(`${Backend.getBackend()}/profile/${user}/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    }).then((resp) => resp.status != 404)
  }

  static statsWithDetail(user: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/profile/${user}/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getFollowingForUser(username: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/profile/${username}/following`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(response => response.json())
  }

  static getBackend() {
    if (['albms-stg.vercel.app', 'stg.albms.net'].includes(window.location.hostname)) {
      return 'https://api-stg.albms.net'
    }
    return 'https://api.albms.net'
  }

  static addAlbumToList(listId: string, albmId: string) {
    return Backend.fetch(`${Backend.getBackend()}/list/item`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        list_id: listId,
        albms_id: albmId,
      }),
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static getListsForUser(username: string) {
    return Backend.fetch(`${Backend.getBackend()}/list/for/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static submitList(note: List) {
    return Backend.fetch(`${Backend.getBackend()}/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(note),
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static submitAlbumNote(note: any) {
    return Backend.fetch(`${Backend.getBackend()}/album/review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(note),
    })
  }

  static getDashStats() {
    return Backend.fetch(`${Backend.getBackend()}/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  static getAlbumStats(id: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/album/${id}/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
      .then(r => r.json())
      .catch(error => {
        console.error('Error fetching data:', error);
      })
  }

  static getAlbumInfo(id: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/album/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getFeedItemStats(id: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/item/${id}/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getFeedItem(id: string) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/item/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getRatingsFeed(index: any) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/ratings?page=${index}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getPopularFeed(index: any = 1) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/popular/week`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getAlbumReviews(id: any, index: any) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/album/${id}/reviews?page=${index}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getPublicFeed(index: any) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/public?page=${index}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getDiscoverFeed(index: any) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/discover?page=${index}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getFeedRatings(username: string, index: number = 1) {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/for/${username}/v2/ratings?page=${index}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static getFeed(username: string, pageIndex: number = 1, sortBy = 'INSERTED_AT') {
    return Backend.fetchWithRetry(`${Backend.getBackend()}/feed/for/${username}?page=${pageIndex}&sort=${sortBy}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    })
  }

  static registerSession(username: string) {
    return Backend.fetch(`${Backend.getBackend()}/profile`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        username: username,
      })
    })
  }
}

export {
  Backend
}

