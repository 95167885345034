import { ScribbleSearch } from "../pages/submit_page"

// FIXME(FELIX): remove this

const Search = ({ setSelectedAlbumMBID, tooltip, initSearchVal = undefined, selectionText = 'View' }) => {
  return (
    <ScribbleSearch
      initSearchVal={initSearchVal}
      setSelectedAlbumMBID={setSelectedAlbumMBID}
      title='Search for an album'
      selectionText={'View'}
      tooltip={tooltip} />
  )
}

export {
  Search
}
