import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Backend } from '../client/backend'
import { ImageWithErr } from '../component/image'
import { useAuth } from '@clerk/clerk-react'
import { Loading } from '../util/loading'
import { sliceContent } from '../util/random'
import { AlbumReviewFeed } from './album_reviews'
import { FaHeart, FaList } from 'react-icons/fa'
import { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";


const AvgRatingStat = ({ val }) => {
  const v = val.toFixed(1)
  return (
    <AlbumStat
      val={<>
        {v / 2} <span className='text-sm'>/ 5</span>
      </>}
      title='Avg. Rating'
    />
  )
}

const AlbumStat = ({ val, title }) => {
  return (
    <div className='flex flex-col mr-4'>
      <div className='font-bold text-xl'>{val}</div>
      <div className=''>{title}</div>
    </div>
  )
}

const AddToListDropdown = ({ albms_id, username }) => {
  const [lists, setLists] = useState<any>([])

  useEffect(() => {
    if (username) { }
    Backend.getListsForUser(username)
      .then((obj: any) => {
        setLists(obj)
      })
  }, [username])

  // get lists
  // handler for add to list of ID (goes on end)
  // need to take highest rank + 1

  const addToListClicked = (id, title) => {
    if (confirm(`Add album to list '${title}'`)) {
      Backend.addAlbumToList(id, albms_id)
        .then((obj) => {
          if (obj) {
            console.log(obj)
          }
        })
    }
  }

  return (
    <div className="dropdown">
      <div tabIndex={0} role='button' className='btn btn-sm'><FaList /> Add To List</div>
      <ul tabIndex={0} className='dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52'>
        {
          lists.map((item) => {
            console.log(item)
            return (
              <li key={item.external_id} onClick={() => addToListClicked(item.external_id, item.title)}><a>{item.title}</a></li>
            )
          })
        }
      </ul>
    </div>
  )
}

const ViewAlbumPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [stats, setStats] = useState<any>()
  const [response, setResponse] = useState<any>()
  const [loading, setLoading] = useState(true)

  const [profile, setProfile] = useState<any>(undefined)
  const session = useSessionContext() as any

  useEffect(() => {
    Backend.myStats()
      .then((obj) => {
        setProfile(obj)
      })
  }, [])

  useEffect(() => {
    if (id) {
      Promise.all([
        Backend.getAlbumInfo(id).then((obj) => obj.json()),
        Backend.getAlbumStats(id)
      ])
        .then(([albumInfo, albumStats]) => {
          document.title = `albms.net: ${albumInfo.artist} - ${albumInfo.title}`
          setResponse(albumInfo);
          setStats(albumStats);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id])

  if (loading) {
    return (
      <>
        <div className='flex flex-row w-full justify-around my-64'>
          <div className='flex flex-col items-center gap-2'>
            <span className='text-4xl'>
              <Loading />
            </span>
          </div>
        </div>
      </>
    )
  }

  if (response) {
    return (
      <>
        <div className='flex flex-col justify-around m-4'>
          <div className='flex flex-row'>
            <div className='flex flex-col md:flex-row w-full'>
              <div className='flex flex-col items-center'>
                <div className='sm:flex md:hidden my-4 w-full flex-col mx-4'>
                  <h2 className='flex flex-row text-3xl font-bold'>{response.title}</h2>
                  <h3 className='text-xl'>{response.artist}</h3>
                </div>
                <div className='mt-4'>
                  <ImageWithErr src={Backend.imageFor(response.albms_id)} />
                </div>
              </div>

              <div className='flex flex-col md:w-2/3 md:mx-4 w-full'>
                <div className='hidden md:flex flex-col'>
                  <h2 className='flex flex-row text-3xl font-bold'>{response.title}</h2>
                  <h3 className='text-xl'>{response.artist}</h3>
                </div>

                <div className='flex flex-col my-6 items-center'>

                  <div className='grid grid-cols-2 gap-2 md:flex md:flex-row w-full text-xs'>
                    <AvgRatingStat val={stats.average_rating} />
                    <AlbumStat val={stats.reviews} title={'Reviews'} />
                    <AlbumStat val={stats.lists} title={'Lists'} />
                  </div>

                  <div className='my-4 flex flex-row w-full justify-start'>
                    <div className='flex flex-row gap-2 items-center'>
                      <button onClick={() => navigate(`/submit?hash=${response.albms_id}`)} className='btn btn-sm btn-success'>
                        Submit Rating / Review
                      </button>

                      {
                        (session.doesSessionExist && profile) && (
                          <>
                            <AddToListDropdown albms_id={response.albms_id} username={profile?.username} />

                            <button className='btn btn-square btn-sm' disabled={true}>
                              <FaHeart />
                            </button>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {response.description && <div className='flex flex-col'>
            <h2 className='font-bold text-lg'>Summary</h2>
            <div className='flex flex-row'>
              <p>{sliceContent(response.description.replace(/(<([^>]+)>)/gi, '').replace('Read more on Last.fm.', ''), 512 * 1.20)}</p>
            </div>
          </div>}
        </div >

        <div className='flex flex-col mx-4'>
          <h3 className='font-bold text-lg'>Recent reviews</h3>
        </div>
        <AlbumReviewFeed showDetails={false} showAlbumCover={false} albumID={response.albms_id} />
      </>
    )
  }

  return (
    <Loading />
  )
}

export {
  ViewAlbumPage,
  AddToListDropdown
}