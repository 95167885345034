import { useEffect, useState } from "react"
import { Backend } from "../client/backend"

const Stat = ({ name, val }) => {
  return (
    <div className="stat">
      <div className="stat-value font-bold">
        {val}
      </div>
      <div className="stat-title text-lg capitalize">
        {name.replaceAll('_', ' ')}
      </div>
    </div>
  )
}

const isVisibleStat = (st) => [
  'reviews', 'ratings', 'users', 'lists', 'list_items',
  'searches', 'advanced_searches', 'members', 'weekly_signups'
].includes(st)

const StatsPage = () => {
  const [stats, setStats] = useState({})

  const load = () => {
    Backend.getDashStats()
      .then((obj) => {
        if (obj && obj.reviews) {
          setStats(obj)
        }
      })
  }

  useEffect(() => {
    load()
    setInterval(() => {
      load()
    }, 5000)
  }, [])

  return (
    <>
      <div className="grid grid-cols-2">
        {
          (Object.entries(stats))
            .filter(([key, _]) => isVisibleStat(key))
            .sort((a: any, b: any) => b[1] - a[1])
            .map(([key, val]: any) => {
              return (
                <Stat name={key} val={val} />
              )
            })
        }
      </div>
    </>
  )
}

export {
  StatsPage
}